import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_TOOLBOX_PENDING:
      return {
        ...state,
        pending: true,
        toolboxTalk: [],
      };
    case types.FETCH_TOOLBOX_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalk: actions.payload.data,
      };
    case types.FETCH_TOOLBOX_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_TOOLBOX_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_TOOLBOX_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalk: [...state.toolboxTalk, actions.payload.data],
      };
    case types.INSERT_TOOLBOX_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_TOOLBOX_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_TOOLBOX_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalk: state.toolboxTalk.map((item) => {
          if (actions.payload.data.toolboxTalk.id === item.toolboxTalk.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_TOOLBOX_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_TOOLBOX_USER_PENDING:
      return {
        ...state,
        pending: true,
        toolboxTalkUser: [],
      };
    case types.FETCH_TOOLBOX_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalkUser: actions.payload.data,
      };
    case types.FETCH_TOOLBOX_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_TOOLBOX_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_TOOLBOX_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalk: state.toolboxTalk.map((item) => {
          if (actions.payload.data.toolboxTalk.id === item.toolboxTalk.id) {
            item.users = [...item.users, actions.payload.data];
          }
          return item;
        }),
      };
    case types.INSERT_TOOLBOX_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_TOOLBOX_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_TOOLBOX_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalkUser: state.toolboxTalkUser.map((item) => {
          if (
            actions.payload.data.toolboxTalkUser.id === item.toolboxTalkUser.id
          ) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_TOOLBOX_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.DELETE_TOOLBOX_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.DELETE_TOOLBOX_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalk: state.toolboxTalk.map((item) => {
          if (actions.payload.data.toolboxTalk.id === item.toolboxTalk.id) {
            item.users = item.users.filter(
              (user) => user.user.email !== actions.payload.data.user.email
            );
          }
          return item;
        }),
      };
    case types.DELETE_TOOLBOX_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
