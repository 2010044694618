import "./globals";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";
import "../src/css/facebook.css"; //Imports custom css for facebook button
import "../src/css/google.css"; //Imports custom css for google button
import "../src/css/hygeian.css"; //Imports the apps CSS file
import "../src/css/app.css"; //Imports the apps CSS file
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import store from "./redux/store/index";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message == "Network Error" || error.message.indexOf("401") >= 0) {
      window.location.href = "/auth/sign-in";
    }
    return Promise.reject(error);
  }
);

window.addEventListener("error", (e) => {
  if (
    e.message ===
      "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
  }
});

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <App />
    </Provider>
  </HttpsRedirect>,
  document.getElementById("root")
);
