import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_CATEGORY_PENDING:
      return {
        ...state,
        pending: true,
        category: [],
      };
    case types.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        pending: false,
        category: actions.payload,
      };
    case types.FETCH_CATEGORY_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_CATEGORIES_PENDING:
      return {
        ...state,
        pending: true,
        categories: [],
      };
    case types.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        pending: false,
        categories: actions.payload,
      };
    case types.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CATEGORY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        pending: false,
        categories: state.categories.map((item) => {
          if (actions.payload.data.value.category.id === item.category.id) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_CATEGORY_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_CATEGORY_SUCCESS:
      return {
        ...state,
        pending: false,
        categories: [...state.categories, actions.payload.data.value],
        // actions.payload,
      };
    case types.INSERT_CATEGORY_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CATEGORY_CLASSIFICATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CATEGORY_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        categories: state.categories.map((item) => {
          if (actions.payload.category.id === item.category.id) {
            item = { ...actions.payload };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_CATEGORY_CLASSIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
