import React from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import store from "../redux/store/index";
import { getHostURL } from "../constants/index";
import { Search as SearchIcon } from "react-feather";

import {
  Grid,
  Hidden,
  Avatar,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Badge,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import UserDropdown from "./UserDropdown";
import { display } from "@material-ui/system";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const AvatarBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(5)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const ProfileName = styled.div`
  position: absolute;
  bottom: 10px;
  right: 140px;
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const AppBarComponent = ({ onDrawerToggle }) => {
  const state = store.getState();
  if (
    state.authReducer.user == null ||
    state.authReducer.user.profile == undefined
  ) {
    window.location.href = "/auth/sign-in";
  }
  const fullname = state.authReducer.user.profile.fullname ?? "new user";
  const avatar = state.authReducer.user.profile.avatar;
  const nophoto = getHostURL() + "static/img/avatars/nophoto.jpg";
  const role = state.authReducer.user.profile.role;
  const suburb = state.authReducer.user.profile.location;
  const sitename = state.authReducer.user.profile.siteName;
  const companyname =
    state.authReducer.user.profile.companyName == null
      ? null
      : state.authReducer.user.profile.companyName +
        (sitename == null ? "" : " - " + sitename) +
        (suburb == null ? "" : " - " + suburb);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <div style={{ paddingTop: 3, paddingBottom: 3 }}>
                <div
                  className="k-primary"
                  style={{ backgroundColor: "#94b414", fontSize: 22 }}
                >
                  {companyname}
                </div>
              </div>
            </Grid>
            <Grid item xs />
            <Grid item>
              {/*<MessagesDropdown />*/}
              {/*<NotificationsDropdown />*/}
              {/*<LanguagesDropdown />*/}
              <ProfileName>
                <div>
                  <x-large>{fullname}</x-large>
                </div>
                <div style={{ textAlign: "center" }}>
                  <small>{role}</small>
                </div>
              </ProfileName>
              <AvatarBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                variant="dot"
              >
                {avatar == null ? (
                  <Avatar alt="{fullname}" src={nophoto} />
                ) : (
                  <Avatar alt="{fullname}" src={avatar} />
                )}
              </AvatarBadge>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
export default withTheme(AppBarComponent);
