import React from "react";
import async from "../components/Async";

import {
  CheckSquare,
  Monitor,
  User as UserIcon,
  Users,
  Thermometer,
  Trello,
  Calendar,
} from "react-feather";
import asyncComponent from "../components/Async";
import {
  AlarmAdd,
  HomeWork,
  Send,
  Settings,
  BookOutlined,
  NoteAddOutlined,
} from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import InviteResponse from "../pages/user/inviteResponse";
import ConPassword from "../pages/auth/ConPassword";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const Panel = async(() => import("../pages/auth/Panel"));
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const TemperatureMeasurementAggregate = asyncComponent(() =>
  import("../pages/dashboards/TemperatureMeasurement/Tsi")
);
const AlarmReport = asyncComponent(() =>
  import("../pages/dashboards/AlarmReport")
);
const CheckListReport = asyncComponent(() =>
  import("../pages/dashboards/CheckListReport")
);
const BatteryVoltage = asyncComponent(() =>
  import("../pages/dashboards/BatteryVoltage")
);
const LiveTemperatures = asyncComponent(() =>
  import("../pages/dashboards/LiveTemperatures")
);
const CheckLists = asyncComponent(() =>
  import("../pages/dashboards/CheckLists")
);
const ToolBoxTalk = async(() => import("../pages/dashboards/ToolBoxTalk"));
const TempGauges2 = asyncComponent(() => import("../pages/dashboards/Gauges2"));
const TempGaugesFlex = asyncComponent(() =>
  import("../pages/dashboards/GaugesFlex")
);
const Profile = async(() => import("../pages/pages/Profile"));
//const InstDList = async(() => import("../pages/pages/InstDList"));
const Instruments = async(() => import("../pages/instrument/instruments"));
const InviteUsers = async(() => import("../pages/user/inviteUsers"));
const ManageUsers = async(() => import("../pages/user/manageUsers"));
const instrumentUnconfigured = async(() =>
  import("../pages/instrument/instrumentUnconfigured")
);

//Deployment
const Deployments = async(() => import("../pages/deployment/deployment"));
const AdminDeployments = async(() =>
  import("../pages/deployment/administration/deployments")
);

//Enterprise Management
const EnterpriseManagement = async(() =>
  import("../pages/enterpriseManagement/enterpriseManagement")
);

//Alarms
const ProcessAlarms = async(() => import("../pages/alarms/process/alarms"));
const SystemAlarms = async(() => import("../pages/alarms/system/alarms"));

//Checklists
const Checklists = async(() => import("../pages/checklist/checklists"));
const TaskTemplates = async(() =>
  import("../pages/checklistManagement/task/taskTemplates")
);
const ChecklistTemplates = async(() =>
  import("../pages/checklistManagement/checklist/checklistTemplates")
);

//Toolbox Talks
const ToolboxTalkManagement = async(() =>
  import("../pages/toolboxTalk/toolboxTalkManagement/toolboxTalkManagements")
);
const ToolboxTalk = async(() =>
  import("../pages/toolboxTalk/toolboxTalk/toolboxTalks")
);

//Notes
const Notes = async(() => import("../pages/note/notes"));
//Reports
const EnterpriseReports = async(() => import("../pages/reports/enterprises"));

const UserReports = async(() => import("../pages/reports/users"));

const SiteReports2 = async(() => import("../pages/reports/sites"));

const TemperatureSummary = async(() => import("../pages/reports/temperature"));

const Spotcheck = async(() => import("../pages/reports/spotcheck"));

const Waste = async(() => import("../pages/reports/waste"));

const HotWater = async(() => import("../pages/reports/hotwater"));

//const StriveForFive = async(() => import("../pages/reports/strivefor5"));

//Schedules
const Schedules = async(() => import("../pages/schedules/schedules"));

// Landing
const Landing = async(() => import("../pages/presentation/Landing"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

//Privacy
const Privacy = async(() => import("../pages/privacy/privacy"));
const TermsConditions = async(() =>
  import("../pages/termsConditions/termsConditions")
);

const UnSubscribe = async(() => import("../pages/unsubscribe/unsubscribe"));
const PrivacyRoutes = {
  id: "privacy",
  path: "/privacy",
  icon: <HomeWork />,
  //badge: "16",
  component: Privacy,
  children: null,
};

const UnSubscribeRoutes = {
  id: "unsubscribe",
  path: "/unsubscribe",
  icon: <HomeWork />,
  //badge: "16",
  component: UnSubscribe,
  children: null,
};

const UserInviteResponse = {
  id: "inviteuseresponse",
  path: "/users/invite/response",
  icon: <HomeWork />,
  component: InviteResponse,
  children: null,
};

const AuthConPassword = {
  id: "conpassword",
  path: "/auth/password/continue",
  icon: <HomeWork />,
  component: ConPassword,
  children: null,
};

const TermsConditionsRoutes = {
  id: "termsConditions",
  path: "/termsConditions",
  icon: <HomeWork />,
  //badge: "16",
  component: TermsConditions,
  children: null,
};

const notesRoutes = {
  id: "Notes",
  path: "/notes",
  icon: <NoteAddOutlined />,
  component: Notes,
};

const reportsRoutes = {
  id: "Reports",
  path: "/reports",
  icon: <BookOutlined />,
  children: [
    {
      path: "/reports/essentials/sites by user",
      name: "Essentials – Sites by User",
      component: SiteReports2,
      guard: AuthGuard,
    },
    {
      path: "/reports/essentials/users by site",
      name: "Essentials – Users by Site",
      component: UserReports,
      guard: AuthGuard,
    },
    {
      path: "/reports/essentials/enterprise, site & location",
      name: "Essentials – Enterprise, Site & Location",
      component: EnterpriseReports,
      guard: AuthGuard,
    },
    {
      path: "/reports/temperature-summary",
      name: "Temperature Summary",
      component: TemperatureSummary,
      guard: AuthGuard,
    },
    {
      path: "/reports/waste-summary",
      name: "Waste Summary",
      component: Waste,
      guard: AuthGuard,
    },
    {
      path: "/reports/spotcheck-summary",
      name: "Spotcheck Summary",
      component: Spotcheck,
      guard: AuthGuard,
    },
    {
      path: "/reports/hot-water-temperature",
      name: "Hot Water Temperature",
      component: HotWater,
      guard: AuthGuard,
    },

    //{
    //  path: "/reports/essentials/strive-for-5",
    //  name: "Essentials – Strive for 5",
    //  component: StriveForFive,
    //  guard: AuthGuard,
    //},
  ],
  component: null,
};

const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: <Settings />,
  children: [
    {
      path: "/settings/users/invite",
      name: "Invite Users",
      component: InviteUsers,
      guard: AuthGuard,
    },
    {
      path: "/settings/users",
      name: "Manage Users",
      component: ManageUsers,
      guard: AuthGuard,
    },
    {
      path: "/settings/EnterpriseManagement",
      name: "Enterprise Management",
      component: EnterpriseManagement,
      children: null,
      guard: AuthGuard,
    },
    {
      path: "/settings/instruments",
      name: "Instrument Management",
      component: Instruments,
      guard: AuthGuard,
    },
    {
      path: "/settings/instrumentUnconfigured",
      name: "Unconfigured Instruments",
      component: instrumentUnconfigured,
      guard: AuthGuard,
    },
    {
      path: "/settings/deployment/instruments",
      name: "Instrument Deployment",
      component: Deployments,
      guard: AuthGuard,
    },
    {
      path: "/settings/deployment/instruments/admin",
      name: "Deployment Administration",
      component: AdminDeployments,
      guard: AuthGuard,
    },
    {
      path: "/settings/checklistManagement/taskTemplate",
      name: "Task Template",
      component: TaskTemplates,
      guard: AuthGuard,
    },
    {
      path: "/settings/checklistManagement/checklistTemplates",
      name: "Checklist Template",
      component: ChecklistTemplates,
      guard: AuthGuard,
    },
    {
      path: "/settings/toolboxTalkManagement",
      name: "ToolboxTalk Management",
      component: ToolboxTalkManagement,
      guard: AuthGuard,
    },
  ],
};

export function getSettingsRoutes(claims) {
  const result = {
    id: "Settings",
    path: "/settings",
    icon: <Settings />,
    component: null,
    children: [],
  };

  if (
    claims["enterprise_management:enterprise:canRead"] == "true" ||
    claims["enterprise_management:canReadOther"] == "true"
  ) {
    result.children.push({
      path: "/settings/EnterpriseManagement",
      name: "Enterprise Management",
      component: EnterpriseManagement,
      guard: AuthGuard,
    });
  }

  if (
    claims["users_management:invite_user:user:canCreate"] == "true" ||
    claims["users_management:invite_user:user:canRead"] == "true" ||
    claims["users_management:invite_user:user:canUpdate"] == "true" ||
    claims["users_management:invite_user:user:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/settings/users/invite",
      name: "Invite Users",
      component: InviteUsers,
      guard: AuthGuard,
    });
  }

  if (
    claims["users_management:manage_user:user:canCreate"] == "true" ||
    claims["users_management:manage_user:user:canRead"] == "true" ||
    claims["users_management:manage_user:user:canUpdate"] == "true" ||
    claims["users_management:manage_user:user:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/settings/users",
      name: "Manage Users",
      component: ManageUsers,
      guard: AuthGuard,
    });
  }

  if (
    claims["instrument_management:calibration:canRead"] == "true" ||
    claims["instrument_management:deployment:canRead"] == "true"
  ) {
    result.children.push({
      path: "/settings/instruments",
      name: "Instrument Management",
      component: Instruments,
      guard: AuthGuard,
    });
  }

  if (claims["unconfigured_instrument:canRead"] == "true") {
    result.children.push({
      path: "/settings/instrumentUnconfigured",
      name: "Unconfigured Instruments",
      component: instrumentUnconfigured,
      guard: AuthGuard,
    });
  }

  if (
    claims["instrument_deployment:deploy:canRead"] == "true" ||
    claims["instrument_deployment:deploy:canReadOther"] == "true" ||
    claims["instrument_deployment:deploy:canUpdate"] == "true" ||
    claims["instrument_deployment:deploy:canUpdateOther"] == "true"
  ) {
    result.children.push({
      path: "/settings/deployment/instruments",
      name: "Instrument Deployment",
      component: Deployments,
      guard: AuthGuard,
    });
  }

  if (
    claims["deployment_administration:deployment:canReadOther"] == "true" ||
    claims["deployment_administration:deployment:canUpdateOther"] == "true"
  ) {
    result.children.push({
      path: "/settings/deployment/instruments/admin",
      name: "Deployment Administration",
      component: AdminDeployments,
      guard: AuthGuard,
    });
  }

  if (
    claims["checklist_management:tasktemplate:canCreate"] == "true" ||
    claims["checklist_management:tasktemplate:canRead"] == "true" ||
    claims["checklist_management:tasktemplate:canUpdate"] == "true" ||
    claims["checklist_management:tasktemplate:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/settings/checklistManagement/taskTemplate",
      name: "Task Template",
      component: TaskTemplates,
      guard: AuthGuard,
    });
  }

  if (
    claims["checklist_management:checklisttemplate:canCreate"] == "true" ||
    claims["checklist_management:checklisttemplate:canRead"] == "true" ||
    claims["checklist_management:checklisttemplate:canUpdate"] == "true" ||
    claims["checklist_management:checklisttemplate:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/settings/checklistManagement/checklistTemplates",
      name: "Checklist Template",
      component: ChecklistTemplates,
      guard: AuthGuard,
    });
  }

  if (
    claims["toolboxtalk_management:canCreate"] == "true" ||
    claims["toolboxtalk_management:canRead"] == "true" ||
    claims["toolboxtalk_management:canUpdate"] == "true" ||
    claims["toolboxtalk_management:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/settings/toolboxTalkManagement",
      name: "ToolboxTalk Management",
      component: ToolboxTalkManagement,
      guard: AuthGuard,
    });
  }

  return result;
}

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Hygeian",
  icon: <HomeWork />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/TemperatureMonitoringTiled",
      name: "Live Monitoring Tiled",
      component: TempGaugesFlex,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/TemperatureMonitoring",
      name: "Live Monitoring Filtered",
      component: TempGauges2,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/Alarms & Notifications",
      name: "Alarms & Notifications",
      component: AlarmReport,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/Checklist Reports",
      name: "Checklist Reports",
      component: CheckListReport,
      guard: AuthGuard,
    },
    // {
    //   path: "/dashboard/TempMonitoring",
    //   name: "Temperatures",
    //   component: TempMonitoring,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/dashboard/TempMonitoringSeries",
    //   name: "Temperature Series",
    //   component: TempMonitoringSeries,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/dashboard/TempMonitoringAggregate",
    //   name: "Temperature Trend",
    //   component: TempMonitoringAggregate,
    //   guard: AuthGuard,
    // },
    {
      path: "/dashboard/TemperatureMeasurementAggregate",
      name: "Temperature Trend",
      component: TemperatureMeasurementAggregate,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/BatteryVoltage",
      name: "Battery Volts",
      component: BatteryVoltage,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/LiveTemperatures",
      name: "Live Temperatures",
      component: LiveTemperatures,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/CheckLists",
      name: "Check List",
      component: CheckLists,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/ToolBoxTalk",
      name: "Toolbox Talk",
      component: ToolBoxTalk,
      guard: AuthGuard,
    },
  ],
  component: null,
};

// const EnterpriseManagementRoutes = {
//   id: "Enterprise Management",
//   path: "/EnterpriseManagement",
//   icon: <Hotel />,
//   component: EnterpriseManagement,
//   children: null,
//   guard: AuthGuard,
// };

const InstrumentsRoutes = {
  id: "Instruments Management",
  path: "/instruments",
  icon: <Thermometer />,
  children: [
    {
      path: "/instruments",
      name: "Instrument Management",
      component: Instruments,
      guard: AuthGuard,
    },
    {
      path: "/instrumentUnconfigured",
      name: "Unconfigured Instruments",
      component: instrumentUnconfigured,
      guard: AuthGuard,
    },
  ],
  component: null,
  guard: AuthGuard,
};

export function getUsersRoutes(claims) {
  const result = {
    id: "Users Management",
    path: "/users",
    icon: <Users />,
    component: null,
    children: [],
    guard: AuthGuard,
  };

  if (
    claims["users_management:invite_user:user:canCreate"] == "true" ||
    claims["users_management:invite_user:user:canRead"] == "true" ||
    claims["users_management:invite_user:user:canUpdate"] == "true" ||
    claims["users_management:invite_user:user:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/users/invite",
      name: "Invite Users",
      component: InviteUsers,
      guard: AuthGuard,
    });
  }

  if (
    claims["users_management:manage_user:user:canCreate"] == "true" ||
    claims["users_management:manage_user:user:canRead"] == "true" ||
    claims["users_management:manage_user:user:canUpdate"] == "true" ||
    claims["users_management:manage_user:user:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/users",
      name: "Manage Users",
      component: ManageUsers,
      guard: AuthGuard,
    });
  }

  return result;
}

const UsersRoutes = {
  id: "Users Management",
  path: "/users",
  icon: <Users />,
  children: [
    {
      path: "/users/invite",
      name: "Invite Users",
      component: InviteUsers,
      guard: AuthGuard,
    },
    {
      path: "/users",
      name: "Manage Users",
      component: ManageUsers,
      guard: AuthGuard,
    },
  ],
};

const DeploymentRoutes = {
  id: "Deployment",
  path: "/deployment",
  icon: <Send />,
  //badge: "16",
  component: null,
  children: [
    {
      path: "/deployment/instruments",
      name: "Instrument Deployment",
      component: Deployments,
      guard: AuthGuard,
    },
    //TODO: Only show if hygiene admin
    {
      path: "/deployment/instruments/admin",
      name: "Deployment Administration",
      component: AdminDeployments,
      guard: AuthGuard,
    },
  ],
};

const AlarmsRoutes = {
  id: "Alarms",
  path: "/alarms",
  icon: <AlarmAdd />,
  children: [
    {
      path: "/alarms/process",
      name: "Process",
      component: ProcessAlarms,
      guard: AuthGuard,
    },
    //TODO: Only show if hygiene admin
    {
      path: "/alarms/system",
      name: "System",
      component: SystemAlarms,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
};

const ChecklistsRoutes = {
  id: "Checklists",
  path: "/checklists",
  icon: <CheckSquare />,
  component: Checklists,
  children: null,
  guard: AuthGuard,
};

const SchedulesRoutes = {
  id: "Schedules",
  path: "/schedules",
  icon: <Calendar />,
  component: Schedules,
  children: null,
  guard: AuthGuard,
};

/* to be deleted later 
export function getChecklistManagementRoutes(claims) {
  const result = {
    id: "Checklist Management",
    path: "/checklistManagement",
    icon: <CheckSquare />,
    component: null,
    children: [],
    guard: AuthGuard,
  };

  if (
    claims["checklist_management:tasktemplate:canCreate"] == "true" ||
    claims["checklist_management:tasktemplate:canRead"] == "true" ||
    claims["checklist_management:tasktemplate:canUpdate"] == "true" ||
    claims["checklist_management:tasktemplate:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/checklistManagement/taskTemplate",
      name: "Task Template",
      component: TaskTemplates,
      guard: AuthGuard,
    });
  }

  if (
    claims["checklist_management:checklisttemplate:canCreate"] == "true" ||
    claims["checklist_management:checklisttemplate:canRead"] == "true" ||
    claims["checklist_management:checklisttemplate:canUpdate"] == "true" ||
    claims["checklist_management:checklisttemplate:canDelete"] == "true"
  ) {
    result.children.push({
      path: "/checklistManagement/checklistTemplates",
      name: "Checklist Template",
      component: ChecklistTemplates,
      guard: AuthGuard,
    });
  }
  return result;
}
*/

const ChecklistManagementRoutes = {
  id: "Checklist Management",
  path: "/checklistManagement",
  icon: <CheckSquare />,
  children: [
    {
      path: "/checklistManagement/taskTemplate",
      name: "Task Template",
      component: TaskTemplates,
      guard: AuthGuard,
    },
    {
      path: "/checklistManagement/checklistTemplates",
      name: "Checklist Template",
      component: ChecklistTemplates,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
  component: null,
};

const ToolboxTalkRoutes = {
  id: "ToolboxTalk",
  path: "/toolboxTalk",
  icon: <AssignmentIcon />,
  children: null,
  guard: AuthGuard,
  component: ToolboxTalk,
};

const ToolboxTalkManagementRoutes = {
  id: "ToolboxTalk Management",
  path: "/toolboxTalkManagement",
  icon: <AssignmentIcon />,
  children: null,
  guard: AuthGuard,
  component: ToolboxTalkManagement,
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <UserIcon />,
  component: Profile,
  children: null,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/panel",
      name: "Panel",
      component: Panel,
      guard: AuthGuard,
    },
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

export const publicLayoutRoutes = [
  UserInviteResponse,
  AuthConPassword,
  PrivacyRoutes,
  UnSubscribeRoutes,
  TermsConditionsRoutes,
];

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  reportsRoutes,
  settingsRoutes,
  notesRoutes,
  //pagesRoutes,
  //projectsRoutes,
  //InstDRoutes,
  //EnterpriseManagementRoutes,
  UsersRoutes,
  InstrumentsRoutes,
  DeploymentRoutes,
  AlarmsRoutes,
  ChecklistsRoutes,
  SchedulesRoutes,
  ChecklistManagementRoutes,
  ToolboxTalkRoutes,
  ToolboxTalkManagementRoutes,
  //EventsRoutes,
  //invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // changelogRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

export const profileLayoutRoutes = [profileRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

export function getSideBarRoutes(claims, isGodMode) {
  const result = [];
  if (claims != undefined) {
    if (
      claims["dashboard:livemonitoring:canRead"] == "true" ||
      claims["dashboard:temperatures:canRead"] == "true" ||
      claims["dashboard:battery:canRead"] == "true" ||
      claims["dashboard:alarm_notification:canRead"] == "true" ||
      claims["dashboard:checklist_reports:canRead"] == "true"
    ) {
      result.push(getDashboardsRoutes(claims));
    }

    if (claims["alarm:canRead"] == "true") {
      result.push(AlarmsRoutes);
    }

    if (
      claims["checklist:canCreate"] == "true" ||
      claims["checklist:canRead"] == "true" ||
      claims["checklist:canUpdate"] == "true" ||
      claims["checklist:canDelete"] == "true"
    ) {
      result.push(ChecklistsRoutes);
    }

    if (
      claims["checklist:canCreate"] == "true" ||
      claims["checklist:canRead"] == "true" ||
      claims["checklist:canUpdate"] == "true" ||
      claims["checklist:canDelete"] == "true"
    ) {
      result.push(SchedulesRoutes);
    }

    if (
      claims["tooltoolboxtalk:canRead"] == "true" ||
      claims["toolboxtalk:canUpdate"] == "true"
    ) {
      result.push(ToolboxTalkRoutes);
    }

    result.push(notesRoutes);

    result.push(getReportsRoutes(claims, isGodMode));

    if (
      claims["enterprise_management:enterprise:canRead"] == "true" ||
      claims["enterprise_management:canReadOther"] == "true" ||
      claims["users_management:invite_user:user:canCreate"] == "true" ||
      claims["users_management:invite_user:user:canRead"] == "true" ||
      claims["users_management:invite_user:user:canUpdate"] == "true" ||
      claims["users_management:invite_user:user:canDelete"] == "true" ||
      claims["enterprise_management:enterprise:canRead"] == "true" ||
      claims["enterprise_management:canReadOther"] == "true" ||
      claims["users_management:manage_user:user:canCreate"] == "true" ||
      claims["users_management:manage_user:user:canRead"] == "true" ||
      claims["users_management:manage_user:user:canUpdate"] == "true" ||
      claims["users_management:manage_user:user:canDelete"] == "true" ||
      claims["instrument_management:calibration:canRead"] == "true" ||
      claims["instrument_management:deployment:canRead"] == "true" ||
      claims["unconfigured_instrument:canRead"] == "true" ||
      claims["instrument_deployment:deploy:canRead"] == "true" ||
      claims["instrument_deployment:deploy:canReadOther"] == "true" ||
      claims["instrument_deployment:deploy:canUpdate"] == "true" ||
      claims["instrument_deployment:deploy:canUpdateOther"] == "true" ||
      claims["deployment_administration:deployment:canReadOther"] == "true" ||
      claims["deployment_administration:deployment:canUpdateOther"] == "true" ||
      claims["checklist_management:tasktemplate:canCreate"] == "true" ||
      claims["checklist_management:tasktemplate:canRead"] == "true" ||
      claims["checklist_management:tasktemplate:canUpdate"] == "true" ||
      claims["checklist_management:tasktemplate:canDelete"] == "true" ||
      claims["checklist_management:checklisttemplate:canCreate"] == "true" ||
      claims["checklist_management:checklisttemplate:canRead"] == "true" ||
      claims["checklist_management:checklisttemplate:canUpdate"] == "true" ||
      claims["checklist_management:checklisttemplate:canDelete"] == "true" ||
      claims["toolboxtalk_management:canCreate"] == "true" ||
      claims["toolboxtalk_management:canRead"] == "true" ||
      claims["toolboxtalk_management:canUpdate"] == "true" ||
      claims["toolboxtalk_management:canDelete"] == "true"
    ) {
      result.push(getSettingsRoutes(claims));
    }
  }
  return result;
}

/* to deleted later
export function getInstrumentsRoutes(claims) {
  const result = {
    id: "Instruments Management",
    path: "/instruments",
    icon: <Thermometer />,
    children: [],
    component: null,
    guard: AuthGuard,
  };

  if (
    claims["instrument_management:calibration:canRead"] == "true" ||
    claims["instrument_management:deployment:canRead"] == "true"
  ) {
    result.children.push({
      path: "/instruments",
      name: "Instrument Management",
      component: Instruments,
      guard: AuthGuard,
    });
  }

  if (claims["unconfigured_instrument:canRead"] == "true") {
    result.children.push({
      path: "/instrumentUnconfigured",
      name: "Unconfigured Instruments",
      component: instrumentUnconfigured,
      guard: AuthGuard,
    });
  }

  return result;
}

*/

/* to be deleted later
export function getEnterpriseManagementRoutes(claims) {
  if (
    claims["enterprise:canRead"] == "true" ||
    claims["enterprise:canReadOther"] == "true"
  ) {
    return {
      id: "Enterprise Management",
      path: "/EnterpriseManagement",
      icon: <Hotel />,
      component: EnterpriseManagement,
      children: null,
      guard: AuthGuard,
    };
  } else {
    return null;
  }
}

*/

export function getReportsRoutes(claims, isGodMode) {
  const result = {
    id: "Reports",
    path: "/reports",
    icon: <BookOutlined />,
    containsHome: false,
    children: [],
    component: null,
  };

  result.children = [];

  result.children.push({
    path: "/reports/essentials/sites by user",
    name: "Essentials – Sites by user",
    component: SiteReports2,
    guard: AuthGuard,
  });

  result.children.push({
    path: "/reports/essentials/users by site",
    name: "Essentials – Users by site",
    component: UserReports,
    guard: AuthGuard,
  });

  result.children.push({
    path: "/reports/essentials/enterprise, site & location",
    name: "Essentials – Enterprise, Site & Location",
    component: EnterpriseReports,
    guard: AuthGuard,
  });

  if (claims["reports:hot_water_temperature:canRead"] == "true") {
    result.children.push({
      path: "/reports/hot-water-temperature",
      name: "Hot Water Temperature",
      component: HotWater,
      guard: AuthGuard,
    });
  }

  if (claims["reports:temperature_summary:canRead"] == "true") {
    result.children.push({
      path: "/reports/temperature-summary",
      name: "Temperature Summary",
      component: TemperatureSummary,
      guard: AuthGuard,
    });
  }

  if (claims["reports:waste_summary:canRead"] == "true") {
    result.children.push({
      path: "/reports/waste-summary",
      name: "Waste Summary",
      component: Waste,
      guard: AuthGuard,
    });
  }

  //if (claims["reports:spotcheck_summary:canRead"] == "true") {
  result.children.push({
    path: "/reports/spotcheck-summary",
    name: "Spotcheck Summary",
    component: Spotcheck,
    guard: AuthGuard,
  });
  //}


  //result.children.push({
  //  path: "/reports/essentials/strive-for-5",
  //  name: "Essentials – Strive-for-5",
  //  component: StriveForFive,
  //  guard: AuthGuard,
  //});
  return result;
}

export function getDashboardsRoutes(claims) {
  const result = {
    id: "Dashboard",
    path: "/dashboard",
    header: "Hygeian",
    icon: <Trello />,
    containsHome: true,
    children: [],
    component: null,
  };

  result.children = [];
  if (claims["dashboard:livemonitoring:canRead"] == "true") {
    result.children.push({
      path: "/dashboard/TemperatureMonitoringTiled",
      name: "Live Monitoring Tiled",
      component: TempGaugesFlex,
      guard: AuthGuard,
    });
  }

  if (claims["dashboard:livemonitoring:canRead"] == "true") {
    result.children.push({
      path: "/dashboard/TemperatureMonitoring",
      name: "Live Monitoring Filtered",
      component: TempGauges2,
      guard: AuthGuard,
    });
  }

  // if (claims["dashboard:temperatures:canRead"] == "true") {
  //   result.children.push({
  //     path: "/dashboard/TempMonitoring",
  //     name: "Temperatures",
  //     component: TempMonitoring,
  //     guard: AuthGuard,
  //   });
  // }
  //
  // if (claims["dashboard:temperatures:canRead"] == "true") {
  //   result.children.push({
  //     path: "/dashboard/TempMonitoringSeries",
  //     name: "Temperature Series",
  //     component: TempMonitoringSeries,
  //     guard: AuthGuard,
  //   });
  // }

  // if (claims["dashboard:temperatures:canRead"] == "true") {
  //   result.children.push({
  //     path: "/dashboard/TempMonitoringAggregate",
  //     name: "Temperature Trend",
  //     component: TempMonitoringAggregate,
  //     guard: AuthGuard,
  //   });
  // }

  if (claims["dashboard:temperatures:canRead"] == "true") {
    result.children.push({
      path: "/dashboard/TemperatureMeasurementAggregate",
      name: "Temperature Trend",
      component: TemperatureMeasurementAggregate,
      guard: AuthGuard,
    });
  }

  if (claims["dashboard:alarm_notification:canRead"] == "true") {
    result.children.push({
      path: "/dashboard/alarms & notifications",
      name: "Alarms & Notifications",
      component: AlarmReport,
      guard: AuthGuard,
    });
  }

  if (claims["dashboard:checklist_reports:canRead"] == "true") {
    result.children.push({
      path: "/dashboard/checklist reports",
      name: "Checklist Reports",
      component: CheckListReport,
      guard: AuthGuard,
    });
  }

  /***
 * coming soon
  if (claims["dashboard:battery:canRead"] == "true") {
    result.children.push({
      path: "/dashboard/BatteryVoltage",
      name: "Battery Volts",
      component: BatteryVoltage,
      guard: AuthGuard,
    });
  }

  // result.children.push({
  //   path: "/dashboard/LiveTemperatures",
  //   name: "Live Temperatures",
  //   component: LiveTemperatures,
  //   guard: AuthGuard,
  // });

  result.children.push({
    path: "/dashboard/CheckLists",
    name: "Check Lists",
    component: CheckLists,
    guard: AuthGuard,
  });

  result.children.push({
    path: "/dashboard/ToolBoxTalk",
    name: "Toolbox Talk",
    component: ToolBoxTalk,
    guard: AuthGuard,
  });
**/
  return result;
}

// Routes visible in the sidebar
//export const sidebarRoutes = [
//dashboardsRoutes,
//pagesRoutes,
//projectsRoutes,
//InstDRoutes,
//EnterpriseManagementRoutes,
//InstrumentsRoutes,
//DeploymentRoutes,
//AlarmsRoutes,
//PrivacyRoutes,
//EventsRoutes,
//invoiceRoutes,
//tasksRoutes,
//calendarRoutes,
//authRoutes,
//componentsRoutes,
//chartRoutes,
//formsRoutes,
//tablesRoutes,
//iconsRoutes,
//mapsRoutes,
//documentationRoutes,
//changelogRoutes,
//];
