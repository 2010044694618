import * as types from "../../constants";
export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.AUTH_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.AUTH_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.AUTH_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
        error: actions.error,
      };
    case types.AUTH_RESET_PASSWORD_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
      };
    case types.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
      };
    case types.AUTH_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        status: false,
      };
    case types.AUTH_FETCH_MANAGED_USERS_PENDING:
      return {
        ...state,
        users: [],
        pending: true,
      };
    case types.AUTH_FETCH_MANAGED_USERS_SUCCESS:
      return {
        ...state,
        users: actions.data.data,
        pending: false,
      };
    case types.AUTH_FETCH_MANAGED_USERS_FAILURE:
      return {
        ...state,
        users: [],
        pending: false,
        error: actions.error,
      };
    case types.AUTH_SELECT_IN_SUCCESS:
      return {
        ...state,
        user: actions.data,
      };
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.data,
      };
    case types.AUTH_SIGN_IN_FAILURE:
      return {
        ...state,
        error: actions.error,
      };
    case types.AUTH_FB_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.data,
      };
    case types.AUTH_GO_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: actions.data,
      };
    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
}
