import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeAttributeFromArray(arr, attribute) {
    let index = -1;
    arr.map((item) => {
      if (attribute.attribute.id === item.attribute.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  switch (actions.type) {
    case types.FETCH_ATTRIBUTE_PENDING:
      return {
        ...state,
        pending: true,
        attribute: [],
      };
    case types.FETCH_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        pending: false,
        attribute: actions.payload,
      };
    case types.FETCH_ATTRIBUTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_ATTRIBUTES_PENDING:
      return {
        ...state,
        pending: true,
        attributes: [],
      };
    case types.FETCH_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        pending: false,
        attributes: actions.payload,
      };
    case types.FETCH_ATTRIBUTES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_ATTRIBUTE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        pending: false,
        attributes: state.attributes.map((item) => {
          if (actions.payload.data.value.attribute.id === item.attribute.id) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_ATTRIBUTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_ATTRIBUTE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        pending: false,
        attributes: [...state.attributes, actions.payload.data.value],
        // actions.payload,
      };
    case types.INSERT_ATTRIBUTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    //TODO: remove attribute
    //removeAttributeFromArray
    case types.REMOVE_ATTRIBUTE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        pending: false,
        attributes: removeAttributeFromArray(
          state.attributes,
          actions.payload.data.value
        ),
      };
    case types.REMOVE_ATTRIBUTE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
