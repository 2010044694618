import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_LOCATION_PENDING:
      return {
        ...state,
        pending: true,
        location: [],
      };
    case types.FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        location: actions.payload.data,
      };
    case types.FETCH_LOCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_LOCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        location: [...state.location, actions.payload.data],
      };
    case types.INSERT_LOCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_LOCATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        location: state.location.map((item) => {
          if (
            actions.payload.data.locid1 === item.locid1 &&
            actions.payload.data.siteid === item.siteid &&
            actions.payload.data.entid === item.entid
          ) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_LOCATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
