import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_ENTERPRISE_PENDING:
      return {
        ...state,
        pending: true,
        enterprise: [],
      };
    case types.FETCH_ENTERPRISE_SUCCESS:
      return {
        ...state,
        pending: false,
        enterprise: actions.payload.data,
      };
    case types.FETCH_ENTERPRISE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_ENTERPRISE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        pending: false,
        enterprise: state.enterprise.map((item) => {
          if (actions.payload.data.entid === item.entid1) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_ENTERPRISE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_ENTERPRISE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_ENTERPRISE_SUCCESS:
      return {
        ...state,
        pending: false,
        enterprise: [...state.enterprise, actions.payload.data],
      };
    case types.INSERT_ENTERPRISE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
