import axios from "axios";
import { getURL } from "../constants/index.js";
import store from "../redux/store";

export function uploadPhotoService(base64) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/profile/photo",
      data: base64,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        alert(error);
        reject(error.response);
      });
  });
}

export function getByEmailService(email) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + "api/user/getbyemail/" + email,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function enableUserService(credential) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/enable",
      data: JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function reInviteUserService(id) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/reinvite",
      data: id,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function proceedInviteUserService(invitetoken, credential) {
  const WebAPI_URL = getURL();
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/invite/proceed?token=" + invitetoken,
      data: JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function deleteInviteUserService(id) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/invite/delete",
      data: id,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function getInvitesService() {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + "api/user/invite",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function responseInviteService(credential) {
  const WebAPI_URL = getURL();
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url:
        WebAPI_URL +
        "api/user/invite/response?action=" +
        credential.action +
        "&token=" +
        credential.token,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function inviteUserService(credential) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/invite",
      data: JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function unLinkedUserService(credential) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/unlinked",
      data: JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function insertUserRolesService(userRoles) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/roles/insert",
      data: JSON.stringify(userRoles),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function updateUserRolesService(userRoles) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/roles/update",
      data: JSON.stringify(userRoles),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function deleteUserRolesService(id) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/user/roles/delete",
      data: id,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
