import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_TYPES_PENDING:
      return {
        ...state,
        pending: true,
        types: [],
      };
    case types.FETCH_TYPES_SUCCESS:
      return {
        ...state,
        pending: false,
        types: actions.payload.data,
      };
    case types.FETCH_TYPES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_ATTRIBUTE_TYPES_PENDING:
      return {
        ...state,
        pending: true,
        attributeTypes: [],
      };
    case types.FETCH_ATTRIBUTE_TYPES_SUCCESS:
      return {
        ...state,
        pending: false,
        attributeTypes: actions.payload.data,
      };
    case types.FETCH_ATTRIBUTE_TYPES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        types: state.types.map((item) => {
          if (actions.payload.data.value.type.id === item.type.id) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_TYPE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_TYPE_SUCCESS:
      return {
        ...state,
        pending: false,
        types: [...state.types, actions.payload.data.value],
        // actions.payload,
      };
    case types.INSERT_TYPE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
