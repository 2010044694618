import * as types from "../../constants";
export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_CHECKLIST_SEARCH_PENDING:
      return {
        ...state,
        pending: true,
        checklist: [],
      };
    case types.FETCH_CHECKLIST_SEARCH_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: actions.payload,
      };
    case types.FETCH_CHECKLIST_SEARCH_ERROR:
      return {
        ...state,
        pending: false,
        checklist: actions.error,
      };
    case types.FETCH_CHECKLIST_REPORTS_PENDING:
      return {
        ...state,
        pending: true,
        reports: [],
      };
    case types.FETCH_CHECKLIST_REPORTS_SUCCESS:
      return {
        ...state,
        pending: false,
        reports: actions.payload,
      };
    case types.FETCH_CHECKLIST_REPORTS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_CHECKLIST_PENDING:
      return {
        ...state,
        pending: true,
        checklist: [],
      };
    case types.FETCH_CHECKLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: actions.payload,
      };
    case types.FETCH_CHECKLIST_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_CHECKLISTS_PENDING:
      return {
        ...state,
        pending: true,
        checklist: [],
      };
    case types.FETCH_CHECKLISTS_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: actions.payload,
      };
    case types.FETCH_CHECKLISTS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CHECKLIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CHECKLIST_SUCCESS:
      //console.log(actions.payload.data);
      return {
        ...state,
        pending: false,
        checklist: state.checklist.map((item) => {
          if (actions.payload.data.checklist.id === item.checklist.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_CHECKLIST_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_CHECKLIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_CHECKLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: [...state.checklist, actions.payload.data],
        // actions.payload,
      };
    case types.INSERT_CHECKLIST_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_CHECKLIST_SCHEDULE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_CHECKLIST_SCHEDULE_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: [...state.checklist, ...actions.payload.data],
      };
    case types.INSERT_CHECKLIST_SCHEDULE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_CHECKLIST_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_CHECKLIST_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: state.checklist.filter(
          (checklist) =>
            checklist.checklist.id != actions.payload.data.checklist.id
        ),
      };
    case types.REMOVE_CHECKLIST_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_CHECKLIST_USER_PENDING:
      return {
        ...state,
        pending: true,
        toolboxTalkUser: [],
      };
    case types.FETCH_CHECKLIST_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        toolboxTalkUser: actions.payload.data,
      };
    case types.FETCH_TOOLBOX_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_CHECKLIST_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_CHECKLIST_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: state.checklist.map((item) => {
          if (
            actions.payload.data.checklistUser.checklistId === item.checklist.id
          ) {
            item.users = [...item.users, actions.payload.data];
          }
          return item;
        }),
      };
    case types.INSERT_CHECKLIST_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CHECKLIST_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CHECKLIST_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: state.checklist.map((item) => {
          if (
            actions.payload.data.checklistUser.checklistId === item.checklist.id
          ) {
            // item.users.map(user => {
            //     if (user.id == )
            // })
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_CHECKLIST_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.DELETE_CHECKLIST_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.DELETE_CHECKLIST_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        checklist: state.checklist.map((item) => {
          if (
            actions.payload.data.checklistUser.checklistId === item.checklist.id
          ) {
            item.users = item.users.filter(
              (user) => user.user.email != actions.payload.data.user.email
            );
          }
          return item;
        }),
      };
    case types.DELETE_CHECKLIST_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CHECKLIST_SELECTED_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CHECKLIST_SELECTED_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        checklistSelectedUser: actions.payload,
      };
    case types.UPDATE_CHECKLIST_SELECTED_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
