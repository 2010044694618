import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.ROLES_SELECT_PENDING:
      return {
        ...state,
        pending: true,
        status: false,
        roles: [],
        justtest: null,
      };
    case types.ROLES_SELECT_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        status: true,
        roles: actions.payload.data.map((item) => {
          return { id: item.id, name: item.name };
        }),
      };
    case types.ROLES_SELECT_IN_FAILURE:
      return {
        ...state,
        error: actions.error,
        pending: false,
        status: false,
      };
    default:
      return state;
  }
}
