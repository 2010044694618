import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_SITE_PENDING:
      return {
        ...state,
        pending: true,
        site: [],
      };
    case types.FETCH_SITE_SUCCESS:
      return {
        ...state,
        pending: false,
        site: actions.payload.data,
      };
    case types.FETCH_SITE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_SITE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_SITE_SUCCESS:
      return {
        ...state,
        pending: false,
        site: [...state.site, actions.payload.data],
      };
    case types.INSERT_SITE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_SITE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_SITE_SUCCESS:
      return {
        ...state,
        pending: false,
        site: state.site.map((item) => {
          if (
            actions.payload.data.siteid1 === item.siteid1 &&
            actions.payload.data.entid === item.entid
          ) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
      };
    case types.UPDATE_SITE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
