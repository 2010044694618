import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_EVENTS_PENDING:
      return {
        ...state,
        pending: true,
        events: [],
      };
    case types.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        events: actions.payload.data,
      };
    case types.FETCH_EVENTS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        events: state.events.map((item) => {
          if (actions.payload.data.value.event.id === item.event.id) {
            item = { ...actions.payload.data.value };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        events: [...state.events, actions.payload.data.value],
        // actions.payload,
      };
    case types.INSERT_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
