import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    //console.log(value);
    let index = -1;
    arr.map((item) => {
      if (value.id === item.taskTemplate.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  switch (actions.type) {
    case types.FETCH_TASK_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
        taskTemplate: [],
      };
    case types.FETCH_TASK_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTemplate: actions.payload,
      };
    case types.FETCH_TASK_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_TASK_TEMPLATES_PENDING:
      return {
        ...state,
        pending: true,
        taskTemplates: [],
      };
    case types.FETCH_TASK_TEMPLATES_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTemplates: actions.payload,
      };
    case types.FETCH_TASK_TEMPLATES_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_TASK_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_TASK_TEMPLATE_SUCCESS:
      //console.log(actions.payload.data);
      return {
        ...state,
        pending: false,
        taskTemplates: state.taskTemplates.map((item) => {
          if (actions.payload.data.taskTemplate.id === item.taskTemplate.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_TASK_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_TASK_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_TASK_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTemplates: [...state.taskTemplates, actions.payload.data],
        // actions.payload,
      };
    case types.INSERT_TASK_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_TASK_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_TASK_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        taskTemplates: removeItemFromArray(
          state.taskTemplates,
          actions.payload.data
        ),
      };
    case types.REMOVE_TASK_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
