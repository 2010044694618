import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_TSI_EVENTS_PENDING:
      return {
        ...state,
        pending: true,
        tsiEvents: [],
      };
    case types.FETCH_TSI_EVENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        tsiEvents: actions.payload.data,
      };
    case types.FETCH_TSI_EVENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        pending: false,
      };
    case types.FETCH_TSI_SERIES_PENDING:
      return {
        ...state,
        pending: true,
        tsiSeries: [],
      };
    case types.FETCH_TSI_SERIES_SUCCESS:
      return {
        ...state,
        pending: false,
        tsiSeries: actions.payload.data,
      };
    case types.FETCH_TSI_SERIES_ERROR:
      return {
        ...state,
        error: actions.error,
        pending: false,
      };
    case types.FETCH_TSI_AGGREGATE_PENDING:
      return {
        ...state,
        pending: true,
        tsiAggregate: [],
      };
    case types.FETCH_TSI_AGGREGATE_SUCCESS:
      return {
        ...state,
        pending: false,
        tsiAggregate: actions.payload.data,
      };
    case types.FETCH_TSI_AGGREGATE_ERROR:
      return {
        ...state,
        error: actions.error,
        pending: false,
      };
    case types.FETCH_TSI_INSTANCES_PENDING:
      return {
        ...state,
        pending: true,
        tsiInstances: [],
      };
    case types.FETCH_TSI_INSTANCES_SUCCESS:
      return {
        ...state,
        pending: false,
        tsiInstances: actions.payload.data,
      };
    case types.FETCH_TSI_INSTANCES_ERROR:
      return {
        ...state,
        error: actions.error,
        pending: false,
      };
    default:
      return state;
  }
}
