import axios from "axios";
import { getURL } from "../constants/index.js";
import store from "../redux/store";

const WebAPI_URL = getURL();

export async function getManagedUsersService() {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + "api/auth/GetManagedUsers",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export async function getManagedUsersBySiteIdService(siteId) {
  const WebAPI_URL = getURL();
  const state = store.getState();
  if (state.authReducer.user == null) window.location.href = "/auth/sign-in";
  const token = state.authReducer.user.profile.token;
  return new Promise((resolve, reject) => {
    const config = {
      method: "GET",
      url: WebAPI_URL + "api/auth/GetManagedUsersBySiteId/" + siteId,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Bearer " + token,
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function signGOIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(WebAPI_URL + "api/auth/go-sign-in", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function signFBIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(WebAPI_URL + "api/auth/fb-sign-in", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(WebAPI_URL + "api/auth/sign-in", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(WebAPI_URL + "api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function resetPassword(email) {
  const WebAPI_URL = getURL();
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/auth/password/reset",
      data: JSON.stringify(email),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function changePassword(credential) {
  const WebAPI_URL = getURL();
  return new Promise((resolve, reject) => {
    const config = {
      method: "POST",
      url: WebAPI_URL + "api/auth/password/change",
      data: JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}
