import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function removeItemFromArray(arr, value) {
    //console.log(value);
    let index = -1;
    arr.map((item) => {
      if (value.id === item.checklistTemplate.id) {
        index = arr.indexOf(item);
      }
      return item;
    });
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  switch (actions.type) {
    case types.FETCH_CHECKLIST_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
        checklistTemplate: [],
      };
    case types.FETCH_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        checklistTemplate: actions.payload,
      };
    case types.FETCH_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    case types.FETCH_CHECKLISTS_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
        checklistTemplates: [],
      };
    case types.FETCH_CHECKLISTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        checklistTemplates: actions.payload,
      };
    case types.FETCH_CHECKLISTS_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CHECKLIST_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CHECKLIST_TEMPLATE_SUCCESS:
      //console.log(actions.payload.data);
      return {
        ...state,
        pending: false,
        checklistTemplates: state.checklistTemplates.map((item) => {
          if (
            actions.payload.data.checklistTemplate.id ===
            item.checklistTemplate.id
          ) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_CHECKLIST_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        checklistTemplates: [...state.checklistTemplates, actions.payload.data],
        // actions.payload,
      };
    case types.INSERT_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.REMOVE_CHECKLIST_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.REMOVE_CHECKLIST_TEMPLATE_SUCCESS:
      return {
        ...state,
        pending: false,
        checklistTemplates: removeItemFromArray(
          state.checklistTemplates,
          actions.payload.data
        ),
      };
    case types.REMOVE_CHECKLIST_TEMPLATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };

    default:
      return state;
  }
}
