import Moment from "react-moment";
import { dateFormat } from "../constants";
import React, { useState } from "react";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Error } from "@progress/kendo-react-labels";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const InputPassword = (fieldRenderProps) => {
  const [visibility, setVisibility] = useState(false);
  const [inputType, setInputType] = useState("password");
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <div style={{ position: "relative" }}>
        <Input type={inputType} {...others} />
        <i
          onClick={() => {
            setVisibility(!visibility);
            setInputType(inputType == "password" ? "text" : "password");
          }}
        >
          {visibility ? (
            <Visibility
              style={{
                position: "absolute",
                right: "10px",
                bottom: "5px",
              }}
            />
          ) : (
            <VisibilityOff
              style={{
                position: "absolute",
                right: "10px",
                bottom: "5px",
              }}
            />
          )}
        </i>
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const InputText = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const InputDatePicker = (props) => {
  const { validationMessage, visited, ...others } = props;
  return (
    <div>
      <DatePicker
        {...others}
        format={"dd/MM/yyyy"}
        defaultValue={props.defaultValue}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const NonNegativeNumericInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <NumericTextBox {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const NumericInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <NumericTextBox {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const minValueValidator = (value) =>
  value >= 0 || value != undefined ? "" : "The value must be 1 or higher";
