import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  function calibratingInstrumentAverage(data) {
    try {
      let totalADC = 0;
      let totalScaled = 0;
      data?.measurement?.map((m) => {
        totalADC = totalADC + m.adcValue;
        totalScaled = totalScaled + m.scaledValue;
        return m;
      });
      data.averageADC = Math.round(totalADC / data.measurement.length, 0);
      data.averageScaled = Number.parseFloat(
        totalScaled / data.measurement.length
      ).toFixed(2);
    } catch (e) {
      //ignore
    }

    return data;
  }
  switch (actions.type) {
    case types.INSERT_INSTRUMENT_CALIBRATION_PENDING:
      return {
        ...state,
        pending: true,
        //calibrationRecordResult: [],
      };
    case types.INSERT_INSTRUMENT_CALIBRATION_SUCCESS:
      return {
        ...state,
        pending: false,
        calibrationRecordResult: actions.payload.data,
      };
    case types.INSERT_INSTRUMENT_CALIBRATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_INSTRUMENT_TO_CALIBRATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.FETCH_INSTRUMENT_TO_CALIBRATE_SUCCESS:
      return {
        ...state,
        pending: false,
        //calibratingInstrument: actions.payload.data,
        calibratingInstrument: calibratingInstrumentAverage(
          actions.payload.data
        ),
      };
    case types.FETCH_INSTRUMENT_TO_CALIBRATE_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
