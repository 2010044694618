import * as types from "../../constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.FETCH_CLASSIFICATION_PENDING:
      return {
        ...state,
        pending: true,
        classification: [],
      };
    case types.FETCH_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        classification: actions.payload,
      };
    case types.FETCH_CLASSIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.FETCH_CLASSIFICATIONS_PENDING:
      return {
        ...state,
        pending: true,
        classifications: [],
      };
    case types.FETCH_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        classifications: actions.payload,
      };
    case types.FETCH_CLASSIFICATIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.UPDATE_CLASSIFICATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.UPDATE_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        classifications: state.classifications.map((item) => {
          if (actions.payload.data.id === item.id) {
            item = { ...actions.payload.data };
          }
          return item;
        }),
        // actions.payload,
      };
    case types.UPDATE_CLASSIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    case types.INSERT_CLASSIFICATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case types.INSERT_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        classifications: [...state.classifications, actions.payload.data],
        // actions.payload,
      };
    case types.INSERT_CLASSIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: actions.error,
      };
    default:
      return state;
  }
}
